import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useFormikContext } from 'formik';

const CheckboxField = ({ label, name, readonly }) => {
    const { values, setFieldValue } = useFormikContext();
    const isChecked = values[name];

    const handleChange = (event) => {
        setFieldValue(name, event.target.checked);
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    name={name}
                    checked={isChecked}
                    onChange={handleChange}
                    color="primary"
                    disabled={readonly || false}
                />
            }
            label={label}
        />
    );
};

export default CheckboxField;
