import React from 'react';
import { useFormikContext } from 'formik';
import { Box, Typography } from '@mui/material';
import { handleImageUpload } from './FormComponent'; // Import handleImageUpload from FormComponent

const ImagePreviewField = ({ label, image }) => {
    const { setFieldValue, values, errors, touched } = useFormikContext();
    const error = touched[image] && errors[image];

    return (
        <Box mt={2}>
            {image && (
                <Box>
                    <img
                        src={image}
                        alt={label}
                        style={{ width: '100px', height: '100px' }}
                    />
                </Box>
            )}
            <input
                type="file"
                onChange={(event) => handleImageUpload(event, setFieldValue, image)}
            />
            {error && (
                <Box mt={1}>
                    <Typography color="error" variant="caption">
                        {error}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default ImagePreviewField;
