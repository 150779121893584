import React from 'react';
import { useFormikContext } from 'formik';
import axios from '../../store/tokenAxios/tokenAxios';
import AsyncSelect from 'react-select/async';

const SearchField = ({ name, label, apiEndpoint, readonly }) => {
    const { setFieldValue, values } = useFormikContext();
    const value = 1;//values[name] || '';

    const loadOptions = (inputValue) => {
        return new Promise(resolve => {
            if (!inputValue || inputValue === '') {
                resolve([]);
            } else {
                setTimeout(() => {
                    axios.get(`${apiEndpoint}?q=${inputValue}`)
                        .then(response => {
                            resolve(response.data);
                        });
                }, 2000);
            }
        });
    };

    return (
        <div>
            <label>{label}</label>
            <AsyncSelect
                cacheOptions
                defaultOptions
                label={label}
                name={name}
                value={value}
                getOptionLabel={e => e.label}
                getOptionValue={e => e.value}
                loadOptions={loadOptions}
                //onChange={(option) => setFieldValue(name, option ? option.value : '')}
                isDisabled={readonly || false}
            />
        </div>
    );
};

export default SearchField;
