import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedGroup} from "../../store/slices/dataSlice";
import {useEffect, useState} from "react";

const GroupSelector = () => {
    const dispatch = useDispatch();
    const groups = useSelector((state) => state.data.groups);
    const selectedGroup = useSelector((state) => state.data.selectedGroup);
    const [usersGroups, setUsersGroups] = useState([]);
    const parsedToken = useSelector((state) => state.auth.parsedToken);
    const roles = useSelector((state) => state.auth.roles);

    useEffect(() => {
        if( !parsedToken) return;
        const isSuperUser = roles.includes('super-admin');
        const filtered = !isSuperUser ? groups.filter( group => group.id === parsedToken.user.group_id) : groups;
        setUsersGroups( filtered)
    }, [groups, parsedToken, roles]);

    const selectGroup = (event) => {
        dispatch( setSelectedGroup( event.target.value))
    }

    if( usersGroups && usersGroups.length === 0) return null;

    return (
        <FormControl variant="outlined" sx={{
            backgroundColor: "white",
            minWidth: "150px",
        }}>
            <Select
                labelId="group-select-label"
                id="group-select"
                value={selectedGroup}
                onChange={selectGroup}
                label="Group"
            >
                {usersGroups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default GroupSelector;