import { createSlice } from '@reduxjs/toolkit';
import {computed, effect, signal} from "@preact/signals-react";

// Helper function to parse the token
const getParsedToken = (token) => {
    if (typeof token !== 'string') {
        return token;
    }
    try {
        return JSON.parse(token);
    } catch (error) {
        console.error("Error parsing token: ", error);
        return null;
    }
};

// Helper function to check if a token is expired
const isTokenExpired = (token) => {
    // Add your logic here to check if the token is expired
    // This depends on the structure of your token
    // For example, if your token includes an expiry timestamp
    return false; // Replace with actual check
};

// Initialize state with data from localStorage
const initialState = {
    token: null, // JSON.parse(localStorage.getItem('dam_admin_token')) || null,
    parsedToken: null,
    roles: [],
    dashRoles: [],
    chatContactId: null
};

if (initialState.token && isTokenExpired(initialState.token)) {
    localStorage.removeItem('dam_admin_token');
    initialState.token = null;
    initialState.parsedToken = null;
    initialState.roles = [];
}

if (initialState.token) {
    const parsedToken = getParsedToken(initialState.token);
    if (parsedToken && parsedToken.user && Array.isArray(parsedToken.user.roles)) {
        initialState.parsedToken = parsedToken;
        initialState.roles = parsedToken.user.roles.map(role => role.name);
        initialState.dashRoles = parsedToken.user.dash_roles;
    }
}

// Auth slice
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth(state, action) {
            const parsedToken = getParsedToken(action.payload);
            if( parsedToken && parsedToken.user && Array.isArray(parsedToken.user.roles)) {
                state.token = action.payload;
                state.parsedToken = parsedToken;
                state.roles = parsedToken.user.roles.map(role => role.name);
                state.dashRoles = parsedToken.dashRoles;
                state.chatContactId = parsedToken.chatContactId??null;
                localStorage.setItem('dam_admin_token', JSON.stringify(action.payload));
                loggedInTokenSignal.value = parsedToken;
            }
        },
        logout(state, action) {
            console.log( action??'No reason given for logout');
            state.token = null;
            state.roles = [];
            state.dashRoles = [];
            state.parsedToken = null;
            state.chatContactId = null;
            loggedInTokenSignal.value = null;
            localStorage.removeItem('dam_admin_token');
        }
    },
});

// this is a signal test
export const loggedInTokenSignal = signal(null);
export const loggedInUser = computed(() => loggedInTokenSignal.value !== null ? loggedInTokenSignal.value.user.name + " " + loggedInTokenSignal.value.user.surname : "No user logged in");
export const isLoggedIn = computed(() => loggedInTokenSignal.value !== null);
effect(() => {
    console.log("logged in user changed: " + loggedInUser.value);
});

// Auth selectors
export const isAuthenticated = (state) => {
    return state && state.auth.roles.length > 0;
};
export const isAdmin = (state) => {
    return state.auth.roles.includes('admin') || state.auth.roles.includes('super-admin');
};
export const isSuperAdmin = (state) => {
    return state.auth.roles.includes('super-admin');
};

export const isStaff = (state) => {
    return state.auth.roles.includes('staff');
};
export const user = (state) => {
    return state.auth.parsedToken ? state.auth.parsedToken.user : null;
};
export const selectRoles = (state) => state && state.auth.roles;

export const selectChatContactId = (state) => state.auth.chatContactId;

// Export actions and reducer
export const { setAuth, logout } = authSlice.actions;
export default authSlice.reducer;
