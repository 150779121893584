import React from 'react';
import { Divider } from '@mui/material';

const FormDivider = ( { caption, ...props} ) => {
    return <Divider {...props}>
        { caption ? caption:'' }
    </Divider>
};

export default FormDivider;
