import axios from 'axios';
import store from '../store';
import {
    setLoading,
    setSpinnerVisibility,
} from '../slices/navSlice';
import {logout} from '../slices/authSlice';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});
const cache = new Map();
instance.interceptors.request.use(
    function (request) {
        const token = localStorage.getItem('dam_admin_token');
        if (token) {
            request.headers.Authorization = `Bearer ${JSON.parse(token).token}`;
        }

        /*const cacheKey = JSON.stringify({ url: request.url, params: request.params });
        if (request.cacheDuration && cache.has(cacheKey)) {
            const cached = cache.get(cacheKey);
            if (Date.now() - cached.timestamp < request.cacheDuration * 1000) {
                return Promise.resolve(cached.response);
            }
            cache.delete(cacheKey);
        }*/

        const hideLoader = request.hasOwnProperty('nospinner');

        store.dispatch(setSpinnerVisibility(hideLoader));
        store.dispatch(setLoading(true));

        return request;
    }
);

instance.interceptors.response.use(
    function (response) {
        store.dispatch(setLoading(false));
        if (response.status === 401) {
            store.dispatch(logout());  // just logout here, and not the thunk logoutUser because the user is already 401 server side
            return Promise.reject(response);
        }

        /*const config = response.config;
        if( config.cacheDuration ) {
            if( response.status >= 200 && response.status < 300) {
                const cacheKey = JSON.stringify({ url: config.url, params: config.params });
                cache.set(cacheKey, {
                    response: response,
                    timestamp: Date.now()
                });
            }
        }*/

        return response;
    },
    function (error) {
        store.dispatch(setLoading(false));
        if (error.response && error.response.status === 401) {
            store.dispatch(logout());
        }
        return Promise.reject(error);
    }
);

export default instance;
