import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../tokenAxios/tokenAxios";
import {setData} from "./dataSlice";
import Helpers from "../../components/helpers/Helpers";
import {INFO, setError} from "./errorSlice";

export const fetchDataThunk = createAsyncThunk(
    'data/fetchData',
    async (branch_id, { dispatch }) => {
        try {
            const response = await axios.get('/api/admin/data', {
                params: {
                    branch_id: branch_id
                }
            });
            dispatch(setData(response.data));
        } catch (error) {
            const show = Helpers.parseError(error);
            dispatch(setError({
                error: show,
                severity: INFO
            }))
        }
    }
);
