import React, {createContext, useCallback, useContext, useState} from 'react';
import {signal} from "@preact/signals-react";

const ChatContext = createContext();

export const useChatContext = () => useContext(ChatContext);

const unreadCount = signal(0);

export const ChatProvider = ({ children }) => {
    const [_chats, _setChats] = useState([]);
    const [messages, setMessages] = useState({});
    const [activeChatId, _setActiveChatId] = useState(null);
    const [getRefreshChatsFlag, setRefreshChatsFlag] = useState(Date.now);
    const [loading, setLoading] = useState(false);

    const addMessageToChat = useCallback((chatId, message) => {
        setMessages((prevMessages) => ({
            ...prevMessages,
            [chatId]: [...(prevMessages[chatId] || []), message],
        }));
    }, []);

    const setMessagesForChat = useCallback((chatId, updateFnOrMessages) => {
        setMessages((prevMessages) => {
            // Determine if the updateFnOrMessages is a function or a direct value
            const isUpdateFn = typeof updateFnOrMessages === 'function';

            // If it's a function, call it with the current messages for the chatId
            const newMessagesForChat = isUpdateFn
                ? updateFnOrMessages(prevMessages[chatId] || [])
                : updateFnOrMessages;

            return {
                ...prevMessages,
                [chatId]: newMessagesForChat,
            };
        });
    }, []);

    const getMessagesForChat = useCallback((chatId) => {
        return messages[chatId] || [];
    }, [messages]);

    const setActiveChatId = useCallback((chatId) => {
        _setActiveChatId(chatId);
    }, []);

    const setChats = useCallback(( updateFnOrChats) => {
        _setChats((prevChats) => {
            // Determine if the updateFnOrMessages is a function or a direct value
            const isUpdateFn = typeof updateFnOrChats === 'function';

            // If it's a function, call it with the current messages for the chatId
            const newChatsForChat = isUpdateFn
                ? updateFnOrChats(prevChats || [])
                : [].concat(updateFnOrChats);

            // get sum of unread_count from newChatsForChat
            unreadCount.value = newChatsForChat.reduce((acc, chat) => acc + chat.unread_count, 0);

            console.log('newChatsForChat', unreadCount.value, newChatsForChat);

            return newChatsForChat;
        });
    }, []);

    const getChats = useCallback(() => {
        return _chats;
    }, [_chats]);

    return (
        <ChatContext.Provider value={{ loading, setLoading, unreadCount, addMessageToChat, setActiveChatId, getMessagesForChat, setMessagesForChat, setChats, getChats, activeChatId, getRefreshChatsFlag, setRefreshChatsFlag }}>
            {children}
        </ChatContext.Provider>
    );
};
