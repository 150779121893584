import {FormControl, MenuItem, Select} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedBranch} from "../../store/slices/navSlice";

const defaultStyle = {}

function BranchSelector({mainOnly = false, selectedBranch, onBranchSelected, styles = defaultStyle}) {
    const selectedGroup = useSelector((state) => state.data.selectedGroup);
    const groups = useSelector((state) => state.data.groups);
    const [branches, setBranches] = useState([]);
    const dispatch = useDispatch();
    const handleBranchChange = (event) => {
        const selectedBranchData = branches.find((branch) => branch.code === event.target.value);
        onBranchSelected(selectedBranchData);
    };

    useEffect(() => {
        const selectedGroupData = groups.find((group) => group.id === selectedGroup);

        if (selectedGroupData) {
            const branches = JSON.parse(selectedGroupData.branches);

            const remappedBranches = Object.entries(branches).map(([key, value]) => ({
                code: key ?? '',
                branch_name: value?.name ?? 'no branches assigned',
                type: value?.type
            }));

            const filtered = mainOnly ? remappedBranches.filter(branch => branch.type === "Main") : remappedBranches;

            setBranches(filtered);

            const branchExists = filtered.some(branch => branch.code === selectedBranch?.code);

            if ( !branchExists && filtered.length > 0) {
                const firstBranch = filtered[0];
                if( selectedBranch !== firstBranch) {
                    dispatch(setSelectedBranch(firstBranch));
                    onBranchSelected(firstBranch);
                }
            }
        }
    }, [dispatch, selectedBranch, selectedGroup, groups, mainOnly, onBranchSelected]);

    if( branches.length === 0) return "Loading..";

    let selectedCode = selectedBranch.code;
    const thisBranch =  branches.find(branch => branch.code === selectedCode);
    if( thisBranch?.code !== selectedBranch.code)
    {
        setSelectedBranch( branches[0]);
        return;
    }

    return (
        <FormControl sx={styles}>
            {selectedBranch && branches && branches.length > 0 ? (
                <Select
                    value={selectedBranch.code}
                    onChange={handleBranchChange}
                >
                    {
                        branches.map((branch) => {
                            return (
                                <MenuItem key={branch.code} value={branch.code}>
                                    {`${branch.branch_name} ${branch.code}`}
                                </MenuItem>
                            );
                        })
                    }
                </Select>
            ) : null}
        </FormControl>
    )
}

export default BranchSelector;
