import { Field } from "formik";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box, Typography } from "@mui/material";

const HintField = ({ hint, readonly, ...props }) => (
    <Box display="flex" alignItems="center">
        <Field {...props} readonly={readonly} />
        { readonly && (
            <LockOutlinedIcon
                fontSize="small"
                sx={{ ml: 0.5, color: 'grey.500' }}
                aria-label="Read-only"
            />
        )}
        {hint && (
            <Typography
                variant="caption"
                sx={{ fontSize: "0.7em", ml: readonly ? 1 : 0 }}
            >
                {hint}
            </Typography>
        )}
    </Box>
);

export default HintField;
