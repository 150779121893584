import './App.css';
import React, {lazy, Suspense} from 'react';
import Register from "./containers/login/Register";
import Reset from "./containers/login/Reset";
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import Login from "./containers/login/Login";
import ConfirmReset from "./containers/login/ConfirmReset";
import BaseLayout from "./containers/baselayout/BaseLayout";
import Terms from "./containers/terms/Terms";
import {Backdrop, CircularProgress} from "@mui/material";
import IdleTimer from "./containers/IdleTimer";
import ErrorBoundary from "./hoc/ErrorBoundary";
import {isSuperAdmin} from "./store/slices/authSlice";
import {useSelector} from "react-redux";
import ChatManager from "./components/shared/chat/ChatManager";
import {ChatProvider} from "./components/shared/chat/ChatContext";
import {LoadScript} from "@react-google-maps/api";
import GuardianPrivacyPolicy from "./containers/privacy/Guardian";

const Dashboard = lazy(() => import("./containers/dashboard/Dashboard"));
const Payments = lazy(() => import("./containers/dashboard/Payments"));
const ShiftDashboard = lazy(() => import("./containers/dashboard/ShiftDashboard"));
const OperationsDashboard = lazy(() => import("./containers/dashboard/OperationsDashboard"));
const Setup = lazy(() => import("./containers/setup/Setup"));
const DocumentsHome = lazy(() => import("./containers/documentshome/DocumentsHome"));
const Reports = lazy(() => import("./containers/reports/Reports"));
const ShiftingDashboard = lazy(() => import("./containers/dashboard/shifting_dashboard/ShiftingDashboard"));
const NoShiftsSubmittedDashboard = lazy(() => import("./containers/dashboard/shifting_dashboard/NoShiftsSubmittedDashboard"));
const NoShowsDashboard = lazy(() => import("./containers/dashboard/shifting_dashboard/NoShowsDashboard"));
const TdtSync = lazy(() => import("./containers/dashboard/shifting_dashboard/TdtSync"));
const AuditDashboard = lazy(() => import("./containers/audit/Audit"));

const Loader = () => (
    <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.modal + 2}} open={true}>
        <CircularProgress/>
    </Backdrop>
)

function App() {
    const isSuperAdmintatus = useSelector(isSuperAdmin);
    const mapKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    console.log('render app');

    const router = createBrowserRouter([
        {path: "/", element: <BaseLayout><Login/></BaseLayout>},
        {path: "/guardian-privacy-policy", element: <GuardianPrivacyPolicy/>},
        {path: "/login", element: <BaseLayout><Login/></BaseLayout>},
        {path: "/reset-password", element: <BaseLayout><ConfirmReset/></BaseLayout>},
        {path: "/set-password", element: <BaseLayout><ConfirmReset mode={'create'}/></BaseLayout>},
        {path: "/dashboard", element: <BaseLayout><Dashboard/></BaseLayout>},
        {path: "/payments", element: <BaseLayout><Payments/></BaseLayout>},
        {
            path: "/shifts",
            element: <BaseLayout><ShiftDashboard/></BaseLayout>,
            children: [
                {path: "shifting", element: <ShiftingDashboard/>},
                {path: "no-shifts-submitted", element: <NoShiftsSubmittedDashboard/>},
                {path: "inactive-drivers", element: <NoShowsDashboard/>},
                {path: "tdt-sync", element: <TdtSync/>},
                {index: true, element: <ShiftingDashboard/>} // Default sub-route
            ]
        },
        {path: "/operations", element: <BaseLayout><OperationsDashboard/></BaseLayout>},
        {path: "/documents", element: <BaseLayout><DocumentsHome/></BaseLayout>},
        {path: "/register", element: <BaseLayout><Register/></BaseLayout>},
        {path: "/reset", element: <BaseLayout><Reset/></BaseLayout>},
        {path: "/audit", element: <BaseLayout><AuditDashboard/></BaseLayout>},
        {path: "/setup", element: <BaseLayout><Setup/></BaseLayout>},
        {path: "/reports", element: <BaseLayout><Reports/></BaseLayout>},
        {path: "/terms", element: <BaseLayout><Terms/></BaseLayout>},
        {path: "*", element: <BaseLayout><Navigate to="/login"/></BaseLayout>}
    ]);

    return (
        <ErrorBoundary fallback={<div>An error occurred in App</div>}>
            {!isSuperAdmintatus &&
                <IdleTimer></IdleTimer>
            }
            { /* <ExtensionChecker/> */ }
            <Suspense fallback={<Loader/>}>
                <ChatProvider>
                    <ChatManager mobile={false}>
                        <LoadScript googleMapsApiKey={mapKey}>
                            <RouterProvider router={router}/>
                        </LoadScript>
                    </ChatManager>
                </ChatProvider>
            </Suspense>
        </ErrorBoundary>
    );
}

export default App;
