import React from 'react';
import { useFormikContext } from 'formik';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import HintField from "./HintField";

const SelectField = ({ name, label, options, readonly, multiple }) => {
    const { setFieldValue, values } = useFormikContext();

    // Use a conditional to set the value if it's undefined or null
    let value;
    if (values[name] === undefined || values[name] === null) {
        value = multiple ? [] : '';
    } else {
        value = values[name];
    }

    return (
        <FormControl fullWidth variant="outlined" disabled={readonly || false}>
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <HintField
                as={Select}
                multiple={multiple}
                labelId={`${name}-label`}
                label={label}
                name={name}
                value={value}
                onChange={(e) => setFieldValue(name, e.target.value)}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </HintField>
        </FormControl>
    );
};

export default SelectField;
