import {useEffect, useState} from 'react';
import {Grid, Paper, Typography, Box, Button, FormControlLabel, Checkbox} from '@mui/material';
import Base from '../base/Base';
import {setShowHeader} from '../../store/slices/navSlice';
import { setAuth } from '../../store/slices/authSlice';
import css from './Login.module.css';
import * as Yup from 'yup';
import FormComponent from "../../components/formik/FormComponent";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {TermsDialog} from "../../components/termsdialog/TermsDialog";

const validationSchema = Yup.object().shape({
    mobile: Yup.string()
        .required('Mobile number is required')
        .matches(/^0\d{9,14}$/, 'Mobile number is not valid'),
    password: Yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters'),
    password_confirmation: Yup.string()
        .required('Password confirm is required')
        .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
});

const initialValues = {
    mobile: '',
    password: '',
    password_confirmation: '',
};

const formFields = [
    {
        label: 'Mobile Number',
        name: 'mobile',
        variant: 'outlined',
        fullWidth: true,
        gridFullWidth: true,
        type: 'tel',
        inputProps: { inputMode: 'numeric' },
    },
    {
        label: 'Password',
        name: 'password',
        variant: 'outlined',
        fullWidth: true,
        gridFullWidth: true,
        type: 'password',
    },
    {
        label: 'Confirm Password',
        name: 'password_confirmation',
        variant: 'outlined',
        fullWidth: true,
        gridFullWidth: true,
        type: 'password',
    },
];

const Register = () => {
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [checked, setChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        dispatch( setShowHeader( false))
        return () => {
            dispatch( setShowHeader( true))
        }
    }, []);

    const onRegistered = ( response, values) => {
        dispatch(setAuth(response.data));
        localStorage.setItem('dam_admin_mobile', values.mobile);
        navigate('/operations')
    }

    return (
        <Base>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={6} lg={4}>
                    <Paper component={Box} p={4} textAlign="center" className={css.paper}>
                        <Typography variant="h6" gutterBottom>
                            Register to Earn Rewards
                        </Typography>
                        <FormComponent
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onRegistered}
                            formFields={formFields}
                            submitLabel="Register"
                            url={`/api/auth/register`}
                            verb="post"
                            additionalData={{ country: "27" }}
                            submitDisabled={!checked}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label={<TermsDialog>I agree to the Terms and Conditions of use</TermsDialog>}
                        />
                        <Box mt={3}>
                            <Button
                                onClick={ () => navigate(-1) }
                                variant="contained"
                                color="secondary"
                                type="button"
                                fullWidth
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Base>
    );
};

export default Register;
